@import "../variables.scss";
@import "../mixins.scss";

.ticket-card {
  transition: height 0.4s linear;
  @include card();
  $self: &;
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: calc(14px + 1vh);
  align-items: center;

  .column1 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }
  .column2 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
  }

  &--error {
    #{ $self } {
      &__thumbnail {
        display: none;
      }

      &__thumbnail-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border: $button-border;

        svg {
          height: 30px;
          color: $orange;
        }
      }
    }
  }

  /* Customize the label (the ticket-card__check) */
  .ticket-card__check {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: calc(8px + 1vh);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 16px;
    height: 100%;
  }

  /* Hide the browser's default checkbox */
  .ticket-card__check input {
    opacity: 0;
    cursor: pointer;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    height: 25px;
    width: 25px;
    background-color: $grey;
  }

  // /* On mouse-over, add a orange background color */
  // .ticket-card__check:hover input ~ .checkmark {
  //   background-color: $orange;
  // }

  /* When the checkbox is checked, add a blue background */
  .ticket-card__check input:checked ~ .checkmark {
    background-color: $orange;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    margin-left: 9px;
    margin-top: 5px;
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .ticket-card__check input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .ticket-card__check .checkmark:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__event-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__event-detail {
    &--expiration-date {
      margin-top: auto;
      font-size: calc(6px + 1vh);
      font-style: italic;
      color: $color__copy--medium;
    }
  }

  &__view-details {
    color: $orange;
    text-decoration: underline !important;
  }

  &__event-header {
    margin: 0;
    color: $color__copy--dark;
  }

  &__event-date {
    margin: 0;
    color: $color__copy--medium;

    &:first-of-type {
      margin: 4px 0 0;
    }

    &:last-of-type {
      margin: 0 0 4px;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  max-height: 66%;
  overflow-y: scroll;

  p {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

@import "../variables.scss";
@import "../mixins.scss";

.footer {
  padding: 10px 25px;

  display: flex;
  align-items: center; // Remove this line to prevent center alignment.
  flex-direction: column;

  height: 120px;
  background-color: $color__grey--light;

  &__link {
    display: inline-block;
    margin-top: 20px;
    font-size: calc(10px + 1vh);
    text-decoration: none;

    &--icon {
      margin: 20px 15px 0;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.general {
  margin-bottom: 25px;

  &__error {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__sad-face {
    width: 140px;
    display: block;
  }

  &__error-text {
    font-size: calc(8px + 1vh);
    color: $color__copy--medium;

    &:first-of-type {
      margin-top: 30px;
    }
  }
}

.general-padding {
  padding: 0 20px;
}

.general-margin-top {
  margin-top: 30px;

  &--short {
    margin-top: 15px;
  }
}

@import "../variables.scss";

html,
body,
#root {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
  margin: 0 auto;
  max-height: 900px;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint("medium+") {
    max-width: 800px;
  }

  &__content {
    padding-top: 10vh; // height of titleBar
    z-index: map-get($z-indexes, "content");
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    &--center-splash {
      height: 100%;
      padding-bottom: 25px;
      background: $color__red--barnes;
      align-items: center;
      justify-content: center;
    }
  }

  &__footer {
    flex-shrink: 0;
  }
}

.payment-details {
  width: 100%;

  .payment {
    width: 100%;
  }

  .details {
    width: 100%;

    &__ticket-upgrade {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ticket-card {
        transform: scale(0.8);
        padding: calc(1px + 1vh) calc(3px + 1vh);
        &--preupgrade {
          transform: scale(0.75);
          opacity: 0.6;
          font-style: italic;
        }
      }

      .arrow-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        .down-arrow {
          height: 12px;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }

      .summary-total {
        text-align: right;
        margin-top: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        h4 {
          font-size: calc(8px + 1vh);
          line-height: calc(8px + 1vh);
          margin: 0 !important;
          padding: 0;
        }

        h3 {
          font-size: calc(10px + 1vh);
          line-height: calc(10px + 1vh);
          margin: 0;
          padding: 0;
        }
        hr {
          margin: 2px;
          width: 50%;
        }
      }
    }
  }
}

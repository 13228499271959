@import "./variables.scss";
// Global styling for style atoms.

/////////////
/// BUTTONS ///
/////////////
.btn-barnes {
  width: 100%;
  @include breakpoint("medium+") {
    width: 400px !important;
  }
  line-height: calc(48px + 1vh);

  font-family: Calibre, sans-serif;
  font-weight: 700;
  font-size: calc(10px + 1vh);

  background-color: $color__red--barnes;
  color: $white;
  border: 2px solid $color__red--barnes;
  border-radius: 0;

  cursor: pointer;
  display: inline-block;
  height: 10vh;
  max-height: 60px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease 0.1s;
  white-space: nowrap;
  width: 100%;

  margin-top: 10px;
  margin-bottom: 10px;

  &:active,
  &:hover {
    border-color: $color__red--barnes-dark;
    background-color: $color__red--barnes-dark;
  }

  transition: color 0.5s, background-color 0.5s, border-color 0.5s;

  &:disabled {
    color: $color__copy--medium;
    border-color: $grey;
    background-color: $grey;
    width: 100%;
    line-height: calc(48px + 1vh);

    font-family: Calibre, sans-serif;
    font-weight: 700;
    font-size: calc(10px + 1vh);

    border-radius: 0;

    cursor: pointer;
    display: inline-block;
    height: 10vh;
    max-height: 60px;
    padding: 0 30px;
    text-align: center;
    text-decoration: none;
    transition: all 0.25s ease 0.1s;
    white-space: nowrap;
    width: 100%;

    margin-top: 10px;
    margin-bottom: 10px;
    &:active,
    &:hover {
      cursor: default;
      color: $color__copy--medium;
      border-color: $grey;
      background-color: $grey;
    }
  }
}

/////////////
/// LINKS ///
/////////////
a {
  color: $color__copy--light;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $color__copy--dark;
  }
}

.a-basic-link {
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:focus:not(.focus-ring) {
    outline: none;
    text-decoration: underline;
  }
}

.a-brand-link {
  color: $color__red--barnes;
  text-decoration: none;

  &:visited {
    color: mix(#000, $color__red--barnes, 10%);
  }

  &:hover,
  &:active {
    color: $color__red--barnes-dark;
  }

  &--no-underline {
    text-decoration: none !important;

    &:active,
    &:visited {
      text-decoration: none !important;
    }
  }

  &--underline {
    text-decoration: none;
    & > span {
      text-decoration: underline;

      &:active,
      &:visited {
        text-decoration: underline;
      }
    }
  }

  &--space {
    &:before {
      content: " ";
      width: 18px;
    }
  }
}

body,
h1,
h2,
h3,
h4 {
  font-family: Calibre, sans-serif;
  color: $white;
  margin: calc(2px + 1vh) 0 calc(2px + 1vh) 0;
}

h1 {
  font-size: calc(24px + 1vh);
  line-height: calc(24px + 1vh);
  @include breakpoint("medium+") {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}

h2 {
  font-size: calc(14px + 1vh);
  line-height: calc(14px + 1vh);
  margin: calc(2px + 1vh) 0 calc(2px + 1vh) 0;
  @include breakpoint("medium+") {
    font-size: 24px !important;
    line-height: 24px !important;
  }
}

h3 {
  font-size: calc(13px + 1vh);
  line-height: calc(13px + 1vh);
  @include breakpoint("medium+") {
    font-size: 16px !important;
    line-height: 16px !important;
  }
}

h4 {
  font-size: calc(12px + 1vh);
  line-height: calc(12px + 1vh);
  @include breakpoint("medium+") {
    font-size: 14px !important;
    line-height: 14px !important;
  }
}

p,
a {
  font-size: calc(10px + 1vh);
  font-family: Calibre, sans-serif;
  @include breakpoint("medium+") {
    font-size: 12px !important;
  }
}

* {
  box-sizing: border-box;
}

.btn {
  border: solid 1px $black;
  border-radius: 5px;
  background: $white;
  padding: 13px 40px;
  text-decoration: none;
  color: $black;
  margin-top: 10px;
  display: inline-block;
  font-size: calc(10px + 1vh);
  cursor: pointer;
  font-weight: bold;
  width: 100%;

  &:hover {
    background: darken($white, 10%);
  }

  &:disabled {
    background: darken($white, 5%);
  }
}

@import "../variables.scss";
@import "../mixins.scss";

.payment-form {
  $self: &;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s, max-height 0.2s ease;

  &--deactivated {
    max-height: 0;
    opacity: 0;
  }

  &__form {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .first-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .number {
        width: 100%;
        font-weight: 700;
      }
    }

    .second-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .expDate,
      .cvc {
        width: 48%;
        font-weight: 700;
      }
    }
  }

  &__input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    display: inline-block;

    font-family: Calibre, sans-serif;
    font-size: calc(10px + 1vh);
    height: calc(24px + 1vh);
    width: 100%;
    padding: 0 12px;

    border-radius: 0 !important;
    border: $button-border;
    color: $darkestgrey;

    &::placeholder {
      color: $button-border-color;
    }

    &:focus {
      color: $darkestgrey;
    }

    &--error {
      border: 1px solid red;
    }
  }

  &__warning {
    @include zeta();
    width: 100%;
    margin: 4px;
    padding: 0;
    font-weight: 500;
    color: $orange;
    text-align: left;
  }

  &__label {
    @include zeta();
    width: 100%;
    margin: 4px;
    padding: 0;
    font-weight: 500;
    color: $white;
    text-align: left;
  }
}

@import "../variables.scss";

.upgrade {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: map-get($z-indexes, "upgrade");

  &__error {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__sad-face {
    width: 140px;
    display: block;
  }

  &__error-text {
    font-size: calc(8px + 1vh);
    color: #6e6e6e;

    &:first-of-type {
      margin-top: 30px;
    }
  }

  &__content {
    width: 100%;
    height: 95%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transform: scale(0.95);
    @include breakpoint("medium+") {
      width: 400px !important;
    }
  }

  .upgrade__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left !important;
  }

  .upgrade__close {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
  }

  .upgrade__title {
    text-align: left;
  }
  .upgrade__subtitle {
    width: 100%;
    text-align: left;
    font-size: calc(10px + 1vh);
    line-height: calc(10px + 1vh);
    margin: 0 !important;
    padding: 0;
    font-weight: 700;
  }

  .tour-upgrade {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__wrapper {
      padding: 0;
      margin: calc(14px + 1vh) 0 calc(14px + 1vh) 0;
    }
  }

  .complete-upgrade {
    width: 100%;

    &__message {
      text-align: left !important;
      height: 50vh;
    }
  }

  .upgrade__footer {
    padding: 0 20px 0 20px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .upgrade__continue {
      width: 100%;
    }
    .upgrade__no-thanks {
      color: $white;
      text-decoration: underline;
    }
  }
}

@import "../variables.scss";

.hero-banner {
  $self: &;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .banner-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--no-image {
    #{ $self } {
      &__header {
        color: $black;
      }

      &__image-wrapper {
        background-color: transparent;
      }

      &__image-overlay {
        opacity: 0 !important;
      }
    }
  }

  &__header-wrapper {
    text-align: center;
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    z-index: map-get($z-indexes, "banner-text");

    padding: 0 20px;
  }

  &__spinner {
    margin: 0;
  }

  &__header {
    font-weight: 700;
    color: $white;
    width: 100%;
    transition: opacity 0.3s;
    font-style: normal;
    font-weight: bold;

    text-align: left;
  }

  &__bottomText {
    font-weight: 700;
    color: $white;
    text-decoration: underline;
    width: 100%;
    transition: opacity 0.3s;
  }

  .down-arrow {
    height: 14px;
    text-align: center;
    padding: 0;
  }

  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  &__image-wrapper {
    overflow: hidden;
    z-index: map-get($z-indexes, "banner-image");
    background-color: $color__grey--light;
    transition: background-color 1s;
    transition: height 0.4s linear;
    position: relative;
  }

  &__image {
    // width: 100%;
    transition: opacity 1s;
  }

  &__image-overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: #000;
    opacity: 0;

    transition: opacity 1s;

    &--loaded {
      opacity: 0.2;
    }

    &:after {
      content: " ";
    }
  }

  a {
    cursor: pointer;
    background-color: transparent;
    border: none;
    text-decoration: none;
  }
}

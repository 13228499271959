@import "../variables.scss";

.title-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: map-get($z-indexes, "title-bar");
  background-color: white;
  padding-bottom: 15px;
  &__logo {
    margin: 18px 0 0 25px;
    height: 37px;
    fill: $orange;

    &.centennial-logo {
      height: 40px;
      margin-left: 23px;
    }
  }
}
